import React, {useEffect} from "react";
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import MainNavbar from "../component/mainNavbar/mainNavbar";
// import { useDispatch } from "react-redux";
import WorkspaceCombined from "../component/workspaceDatabase/workspaceCombined/workspaceCombined";
import "./css.scss";
// import { bulkAdd } from "../store/database/databaseThunk";
import SingleDatabase from "../component/workspaceDatabase/singledatabase/singleDatabase";
import variables from "../assets/styling.scss";
import  { customUseSelector } from "../store/customUseSelector";
import { useDispatch } from "react-redux";
import { getDbsOfOrg } from "../store/database/databaseThunk";
import { useParams } from "react-router";

export default function LandingPage() {
  const dispatch = useDispatch();
  const params = useParams();
  // const userId = customUseSelector((state) => state.user.userId);
  const alldbs = customUseSelector((state) => state.dataBase.orgId || []);
  const orgId = params.orgId;
  const dbStatus = customUseSelector((state) => state.dataBase.status);

  let dbs = [];
  if (alldbs && typeof alldbs === "object") {
    dbs = Object.values(alldbs)
      .flatMap((value) => (Array.isArray(value) ? value : []))
      .filter((element) => element && element.deleted);
  }

  useEffect(() => {
    dispatch(getDbsOfOrg(orgId));
  }, []);

  return (
    <Container maxWidth="true" className="landingpagemaincontainer">
      <Box>
        <MainNavbar />
      </Box>
      {
        dbStatus === 'loading' && <CircularProgress className="landingpageloader"/> 
      }
      {dbStatus === 'succeeded' && <Box>
        <Box>
          <WorkspaceCombined />
        </Box>
        <Box>
          {dbs?.length > 0 && (
            <Typography
              variant={variables.landingpagetitlevariant}
              fontWeight={variables.titleweight}
              className="deletedbtitle"
            >
              Deleted Databases
            </Typography>
          )}
          <Grid className="landingpagegrid" container spacing={2}>
            {dbs.map((db, index) => (
              <Box key={db._id} className="boxfordeleteddbs">
                <SingleDatabase
                  db={db}
                  orgId={db.org_id}
                  dblength={dbs?.length}
                  index={index}
                />
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>}
      {
        dbStatus === 'failed' && (
          <Typography className="landingpageloader">Failed to load Databases</Typography>
        )
      }
    </Container>
  );
}
