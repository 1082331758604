import axiosInstance from '../interceptor/interceptor';


const createFilter = async (db_id,tableName,data) =>
{
    return await axiosInstance.post(`/dbs/${db_id}/${tableName}/filter`,data)
}

const deleteFilter = async (db_id,tableName,data) =>
{
    return await axiosInstance.patch(`/dbs/${db_id}/${tableName}/deleteFilter`,data)
}

const updateQuery = async (db_id,tableName,data) =>
{
    return await axiosInstance.patch(`/dbs/${db_id}/${tableName}/updateFilter`,data)
}

const runQueryonTable = async(dbId,tableName,filterId,pageNo,limit)=>{
    return await axiosInstance.post(`/dbs/${dbId}/${tableName}/${filterId}/runQuery`,{pageNo:pageNo||1,limit:limit||100})
}
const filterQueryByAi = async (dbId,data) =>
{
    return await axiosInstance.post(`/dbs/${dbId}/filterquery`,data)   
}

const updateKanban = async (dbId, tableId, filterId,  data) => {
    return await axiosInstance.patch(`/dbs/${dbId}/${tableId}/${filterId}/kanban`, data);
}

export{
    createFilter,
    deleteFilter,
    updateQuery,
    runQueryonTable,
    filterQueryByAi,
    updateKanban
 }