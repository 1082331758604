import React, {memo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button,  Popover,  Typography } from '@mui/material';
import { toast } from 'react-toastify';
import '../dbSnapshotsMenu/dbSnapshotsMenu.scss';
import {  restoreTable1 } from '../../../store/allTable/allTableThunk';
import { customUseSelector } from '../../../store/customUseSelector';
import { getAllTableInfo } from '../../../store/allTable/allTableSelector';
import { getOrgUsers } from '../../../store/database/databaseSelector';


function RestoreTableMenu(props) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const currentOrgUsers = customUseSelector((state) => getOrgUsers(state));
  let { deletedTables } = customUseSelector((state) => getAllTableInfo(state));
  deletedTables = Object.entries(deletedTables || {});

  const [inside, setInside] = useState(null);

  const handleClose = () => {
    props.setOpen(false);
  };

  const restoreTableInDb = async (tableName) => {

    dispatch(restoreTable1({ tableName, dbId: params.dbId })).then(() => {
      navigate(`/${params.orgId}/db/${params.dbId}/table/${tableName}`);

    }).catch ((error)=> {
        console.log(error);
        toast.error('Failed to restore table.');
      });
      handleClose();
  };
  return (
    <Popover
    id = {props?.id}
    anchorEl={props?.anchorEl}
    open={props?.open}
    onClose={props?.closePopper}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    >
      <Box className='table-style'>
        <div className="popupheader">
          <Typography  id="title" variant="h6" component="h2">
            Deleted Tables
          </Typography>
          <div>
            {inside && <KeyboardBackspaceIcon className="dbsnapshot-spaceIcon" onClick={() => setInside(null)} />}
            <CloseIcon className="dbsnapshot-spaceIcon" onClick={handleClose} />
          </div>
        </div>
        {!inside ? (
          <Box className="snapshot-setInside">
            {deletedTables.length > 0
              ?
              <>
              <Typography className='table-typography'>Click any table to restore</Typography>
              {deletedTables.map((item) => (
                  <Button
                    onClick={() => {
                      setInside(item);
                    }}
                    key={item[0]}
                    className="mui-button-outlined snapshot-button"
                  >
                    {item[1].tableName.toString() + ", " + new Date(item[1]?.backup?.deletedAt * 1000)?.toLocaleString() + ", By: " + currentOrgUsers[item[1]?.backup?.deletedBy]?.name}
                  </Button>
                ))}
                </>
              : <Typography className='snapshot-notcreated'>Tables not deleted yet.</Typography>}
          </Box>
        ) : (
          <Box className="snapshot-box">
            <Typography fontWeight="bold">Do you want to restore this table? </Typography>
            <div className="snapshot-div">
              <Button variant="contained" className="mui-button snapshot-button2" onClick={() => { restoreTableInDb(inside?.[0]); }}>Restore</Button>
            </div>
          </Box>
        )}      
      </Box>
    </Popover>

  );
}

RestoreTableMenu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  revisionbuttonref: PropTypes.any,
  dbId: PropTypes.any,
  id: PropTypes.any,
  closePopper: PropTypes.func,
  anchorEl : PropTypes.any
};

export default memo(RestoreTableMenu);
