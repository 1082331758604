import React, { useEffect } from 'react';
import { Avatar, Box, MenuItem, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
// import { customUseSelector } from '../../store/customUseSelector';
// import { switchOrg, switchOrg } from '../../api/index'
import { switchOrgBE } from '../../api/orgApi';


// import { getCurrentEnvironment, setInCookies } from '../../utils/utilities'
import { setInCookies, getCurrentEnvironment } from '../../utility/storageHelpers';
import { fetchAllOrgThunk, switchOrgThunk } from '../../store/database/databaseThunk';
// import FunctionsActionsButton from '../functiondashboard/workFlow/workFlowComponent/functionsActionsButton/functionsActionsButton'

export default function AllOrgs({
  orgListVariable,
  showTextField,
  returnComponentForRenamingScript,
  createOrg,
  isModal,
  orgId,
  // handleActionBtns
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  // const options = ['Rename']

  useEffect(() => {
    dispatch(fetchAllOrgThunk())
  }, [])

  async function selectWorkspace(orgId) {

    const orgid = orgListVariable.filter((item) => item.id === orgId)
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local') {
      const domain = getCurrentEnvironment()
      const response = await switchOrgBE({ orgId: orgid[0]?.id, orgName: orgid[0]?.name })

      // localStorage.setItem('accessToken', response?.data?.data)
      setInCookies(domain, response?.data?.data)
    }
    
    dispatch(switchOrgThunk({ orgId : orgid[0].id})).then(() => {
      navigate(`/${orgid[0].id}/dashboard`);
    });
    // dispatch(clearScriptsList())
  }

  const allOrgs =
    orgListVariable.length > 0 &&
    orgListVariable.map((org) => {
      if (org.id === 'new_workspace')
        return (
          <MenuItem key={org.id} className='color-white workspace__element flex w-100 box-sizing-border-box cur-pointer'>
            <TextField
              inputProps={{ maxLength: 25 }}
              autoFocus
              workflowtitle='true'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  createOrg(e.target.value)
                }
              }}
              fullWidth
              onBlur={(e) =>
                createOrg(e.target.value)
              }
              placeholder='Workspace title'
              noborder='true'
            />
          </MenuItem>
        )
      if (showTextField === org.id) {
        return returnComponentForRenamingScript(org)
      }
      return (
        <MenuItem
          key={org.id}
          onClick={() => selectWorkspace(org.id)}
          className={`text-overflow-eclipse ${isModal ? 'workspace__modal__element' : 'workspace__element '} ${orgId === org.id ? 'selected-org' : ''
            } gap-2 flex w-100 box-sizing-border-box cur-pointer`}
        >
          <Avatar variant='rounded'>
            <Typography className='text-overflow-eclipse' variant='bold'>
              {org?.name?.toUpperCase()?.substring(0, 2) || ''}
            </Typography>
          </Avatar>
          <Box marginLeft="10px" className='color-white text-overflow-eclipse w-100'>{org?.name}</Box>
          <Box className='flex-end'>

          </Box>

        </MenuItem>
      )
    })
  return allOrgs
}
