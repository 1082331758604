import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Avatar, Box, Divider, ListItemIcon, MenuItem, TextField, Typography } from '@mui/material'
// import './WorkspacesComponent.scss'
import { Logout } from '@mui/icons-material'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
// import { useCustomSelector } from '../../utils/deepCheckSelector'
import { customUseSelector } from '../../store/customUseSelector'
// import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { allOrg } from '../../store/database/databaseSelector'
// import config from '../../config'
// import { renameOrgThunk, saveOrgs } from '../../store/orgs/orgsThunk'
import { renameOrgThunk, saveOrgs } from '../../store/database/databaseThunk'
// import { saveOrgs } from '../../store/orgs/orgsThunk'
// import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
// import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
// import { ParamsEnums } from '../../enums'
// import { $ReduxCoreType } from '../../types/reduxCore.ts'
// import { getCurrentEnvironment, removeCookie, validateOrgName } from '../../utils/utilities'
import { clearUserData } from '../../utility/storageHelpers'
import AllOrgs from './allOrgs'
// import { logoutUserFromMsg91 } from '../../api/index'
import { logoutUser } from '../../api/userApi'
// import selectActiveUser from '../../store/user/userSelector'
import { selectActiveUser } from '../../store/user/userSelector'

function OrgPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams();
  const userDetails = customUseSelector((state) => selectActiveUser(state))

  // const orgList = customUseSelector(
  //   (state) => selectActiveOrgs(state)?.sort((a, b) => a?.title?.localeCompare(b?.title)) || []
  // )
  const orgList = customUseSelector(
    (state) => allOrg(state)
  )


  // useEffect(() => {
  //   const handleEscKeyPress = (event) => {
  //     if (event.key === 'Escape') {
  //       dispatch(closeModalRedux())
  //     }
  //   }

  //   document.addEventListener('keydown', handleEscKeyPress)

  //   return () => {
  //     document.removeEventListener('keydown', handleEscKeyPress)
  //   }
  // }, [dispatch])

  const [newTitle, setNewTitle] = useState('')
  const [showTextField, setShowTextField] = useState('-1')
  const [orgListVariable, setOrgListVariable] = useState(orgList || [])

  useEffect(() => setOrgListVariable(orgList), [orgList])
  const addNewWorkspace = (e) => {
    if(process.env.REACT_APP_API_ENVIRONMENT !== 'local'){
      window.location.href = process.env.REACT_APP_VIASOCKET_DOMAIN_URL + "/org";
    }
    if (!orgListVariable.some(org => org.id == 'new_workspace')) {
      setOrgListVariable([...orgListVariable, { id: 'new_workspace' }])
    } else {
      setOrgListVariable(orgListVariable.filter(org => org.id !== 'new_workspace'))
    }
    e.preventDefault()
    e.stopPropagation()
  }
  const handleLogOut = async () => {
    try {
      await logoutUser()
      clearUserData();
      if (process.env.REACT_APP_API_ENVIRONMENT === 'prod') window.location.replace(process.env.REACT_APP_VIASOCKET_HOME_PAGE_URL)
      else navigate('/')
    } catch (e) {
      console.error(e)
    }
  }
  const createOrg = async (orgName) => {
    setOrgListVariable(orgListVariable.filter(org => org.id !== 'new_workspace'))
    if (!orgName) return
    setShowTextField('-1');
    dispatch(
      saveOrgs({
        name: orgName || '',
        count: orgList?.length
      })
    ).then(res => {
      if(!res.error){
        navigate(`/${params.orgId}/dashboard`);
      }
    })
  }

  const handleActionBtns = (org) => {
    //  (option) {
    //   case 'Rename':
    setShowTextField(org.id)
    //     break
    //   default:
    //     break
    // }
  }

  const handleTextFieldClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setNewTitle(e.target.value)
  }

  const inputFieldHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleRenameOnClick = (org, value) => {
    // if (!validateOrgName(value, orgList, setOrgListVariable)) return
    const orgId = org.id
    dispatch(renameOrgThunk({ body: { name: value }, orgId }))
  }

  const returnComponentForRenamingScript = (org) => {
    return (
      <Box
        key={org.id}
        className={`${'workspace__element '} flex-spaceBetween-center w-100 box-sizing-border-box `} >
        <Box className='flex-start-center w-100 gap-3'>
          <Avatar variant='rounded'>
            <Typography variant='bold'>{org?.name?.toUpperCase()?.substring(0, 2) || ''}</Typography>
          </Avatar>
          <TextField
            inputProps={{ maxLength: 25 }}
            size='small'
            noborder='true'
            autoFocus
            fullWidth
            className='title-textfield'
            scriptname='true'
            onBlur={() => {
              setShowTextField('-1')
              handleRenameOnClick(org, newTitle)
            }}
            id='outlined-helperText'
            defaultValue={org.name}
            onChange={handleTextFieldClick}
            onClick={inputFieldHandler}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowTextField('-1')
                handleRenameOnClick(org, newTitle)
              }
            }}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={`${'workspace__page  w-100 overflow-hide flex-col-start-center'
        } box-sizing-border-box flex-center-start `}
    >
      <h1>Select a WorkSpace</h1>
      <Box className={`w-100 gap-3 workspace__page-cont pt-2 workspace__cont`}>
        <Box className='flex-start-center w-100 box-sizing-border-box'>
          <Typography
            className='py-2 px-3'
            sx={{ fontFamily: '"Inter", sans-serif', fontSize: '14px', lineHeight: '16px', fontWeight: 500, margin: '10px' }}
            variant='h4'
          >
            {userDetails.email}
          </Typography>
        </Box>
        <Divider />

        <Box className='workspace__orglist w-100 column my-2 gap-1'>
          <AllOrgs
            orgListVariable={orgListVariable}
            showTextField={showTextField}
            returnComponentForRenamingScript={returnComponentForRenamingScript}
            createOrg={createOrg}
            handleActionBtns={handleActionBtns}
          />
        </Box>
        <Divider />
        <Box marginLeft="10px" marginTop="10px" className='footer'>
          <MenuItem onClick={addNewWorkspace}>
            <ListItemIcon>
              <LibraryAddOutlinedIcon className='color-white' fontSize='small' />
            </ListItemIcon>
            <Box className='color-white'>Create new workspace</Box>
          </MenuItem>
          <MenuItem onClick={handleLogOut}>
            <ListItemIcon>
              <Logout className='color-white' fontSize='small' />
            </ListItemIcon>
            <Box className='color-white'>Logout</Box>
          </MenuItem>
        </Box>
      </Box>
    </Box>
  )
}
export default React.memo(OrgPage)
