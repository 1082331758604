import { createAsyncThunk } from "@reduxjs/toolkit";
import { renameDb,moveDb,deleteDbForUser, restoreDbForUser, getDbsByOrgId} from "../../api/dbApi";
import { addUserInOrg, createOrg, getAllOrgs, removeUserInOrg, updateOrg, updateUserType, getDbsByOrg, switchOrg, addUserInOrgBE, switchOrgBE } from "../../api/orgApi";
import {  getAllUsers } from "../../api/userApi";
import { createDbThunkPayloadType, RenameDbThunkPayloadType, DeleteAndRestoreDbThunkPayloadType, RenameOrgThunkPayloadType, CreateOrgThunkPayloadType, ShareOrgThunkPayloadType } from "../../types/databaseDataType";
import { getCurrentEnvironment, setInCookies } from "../../utility/storageHelpers";
// import {  any } from "../../types/databaseDataType";


export const bulkAdd = createAsyncThunk(
    "organdDb/bulkAdd", async (payload : {orgId : string, userId : string }) => {
        // const data = await findUserByEmail(payload?.userId);
        const data = await getDbsByOrgId(payload.orgId);
        localStorage.setItem("userid", payload.userId);
        var result = {};
        try {
            data?.data?.data?.dbs.map((item) => {
               result[item.org_id.id] = result[item?.org_id?.id] ? [...result[item?.org_id?.id], item] : [item]
            })
        } catch (error) {
                console.log(error)
        }
        const allorgs = await getAllOrgs()
        const ans = {
            result:result,
            allorgs:allorgs?.data?.data
        }
        return ans;
    }
);

export const createDbThunk = createAsyncThunk(
    "organdDb/createDbThunk", async (payload:createDbThunkPayloadType) => {
        return payload?.data;
    }
);

export const moveDbThunk=createAsyncThunk(
"organdDb/moveDbThunk",async({orgId,dbId,data,currentOrgId}:{orgId:string,dbId:string,data:{newOrgId:string},currentOrgId:string})=>{
        const response=await moveDb(currentOrgId,dbId,data);
        const moveData  = {
            data1 : response?.data?.data,
            orgId : orgId,
            currentOrgId
        }
        return moveData ;
    }
);

export const renameDBThunk = createAsyncThunk(
    "organdDb/renameDBThunk", async (payload:RenameDbThunkPayloadType) => {
        await renameDb(payload.orgId, payload.id, payload.data);
        const data = {
            name: payload.data.name,
            _id: payload.id,
            org_id: payload.orgId
        }
        return data;
    }
);




export const deleteDbThunk = createAsyncThunk(
    "organdDb/deleteDbThunk", async (payload:DeleteAndRestoreDbThunkPayloadType) => {
        const data =   await deleteDbForUser(payload.orgId, payload.dbId);
        return data?.data?.data;
    }
);

export const restoreDbThunk = createAsyncThunk( 
    "organdDb/restoreDbThunk", async (payload:DeleteAndRestoreDbThunkPayloadType) => {
        const data = await restoreDbForUser(payload.orgId, payload.dbId);
        return data?.data?.data;
    }
);
export const renameOrgThunk = createAsyncThunk(
    "organdDb/renameOrgThunk", async (payload:RenameOrgThunkPayloadType) => {
        const data = await updateOrg(payload.data);
        return data.data;
    }
);


export const createOrgThunk = createAsyncThunk(
    "organdDb/createOrgThunk", async (payload:CreateOrgThunkPayloadType) => {
        const data = await createOrg({ name: payload.name });
        const allorgs = await getAllOrgs()
     
        const allData= {
            data:data.data.data,
            allorgs:allorgs?.data?.data
        }
        return allData;
    }
);

export const saveOrgs = createAsyncThunk('orgs/saveOrgs', async (payload : any, {dispatch}) => {
    const { data } = await createOrg(payload.name)
    await dispatch(switchOrgThunk({orgId : data?.data?.id}))
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local') {
      const domain = getCurrentEnvironment()
      const response = await switchOrgBE({ id: data?.data?.id, name: data?.data?.name })
      setInCookies(domain, response?.data?.data)
    }
    return data.data
})

export const shareUserInOrgThunk = createAsyncThunk(
    "organdDb/shareUserInOrgThunk", async (payload:ShareOrgThunkPayloadType) => {
        const addedUser = await addUserInOrg(payload.data).then(data => data.data.data);
        await addUserInOrgBE({userId : addedUser.id , ...payload.data}, payload.currentOrgId);
        // const allorgs = await getAllOrgs()
        // const allData= {
        //     allorgs:allorgs?.data?.data
        // }
        // return allData;
        return { addedUser }
    }
);  
export const updateUserInOrgThunk=createAsyncThunk(
    "organdDb/updateUserInOrgThunk", async (payload:ShareOrgThunkPayloadType) => {
        await updateUserType(payload.orgId, payload.data);
        const allorgs = await getAllOrgs()
        const allData= {
            allorgs:allorgs?.data?.data
        }
        return allData;
    }
)
export const removeUserInOrgThunk = createAsyncThunk(
    "organdDb/removeUserInOrgThunk", async (payload:ShareOrgThunkPayloadType) => {
        await removeUserInOrg(payload.orgId, payload.userId);
        return payload;
    }
);  

export const fetchAllOrgThunk = createAsyncThunk(
    "organdDb/fetchAllorgThunk", async () => {
        const orgList = await getAllOrgs()
        // if (orgList.length === 0) dispatch(openModalRedux({ modalType: 'createOrg' }))
        return orgList?.data?.data || []
    }
)

export const getDbsOfOrg = createAsyncThunk(
    "organdDb/getDbsOfOrg", async(payload : any) => {
        const dbs = await getDbsByOrg(payload);
        const orgUsers = await getAllUsers();
        return {dbs : dbs.data.data, users : orgUsers.data.data.data, orgId: payload};
    }
)

export const switchOrgThunk = createAsyncThunk(
    "organdDb/switchOrg", async(payload : { orgId : any}) => {
        await switchOrg({ company_ref_id : payload.orgId});
        return payload;
    }
)
export const addUserAccessInOrgThunk = createAsyncThunk(
    "org/adduseraccess", async (payload:any) => {
        const addedUser = await addUserInOrg({user:payload.data}).then(data => data.data.data);
        return {addedUser}; 
    }
)