import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from "prop-types";
import createSession from '../utility/sessionCreator';
const Protected = ({ children }) => {
  if(!sessionStorage.getItem('sessionId')){
    createSession();
  }
  const token = localStorage.getItem("proxy_auth_token");
  if (!token) {
    return <Navigate to='/' />;

  }
  return children;
};

export default Protected;

Protected.propTypes = {
  children: PropTypes.node
};