import React, { useState } from "react";
import {
  Box, Button, ClickAwayListener, Dialog, DialogContent, FormControl, IconButton, InputLabel, MenuItem, Select, Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/Mode'
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { shareOrgModalstyle } from "../../muiStyles/muiStyles";
import CustomTextField from "../../muiStyles/customTextfield";
export default function ShareOrgModal(props) {
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState(111);
  const userId = localStorage.getItem("userid");

  const classes = shareOrgModalstyle();
  const [editable, setEditable] = useState(null);
  const users = props.orgUsers ? Object.entries(props.orgUsers).map(user => user[1]) : null;
  const handleClose = () => {
    props.setShareOrg(false);
    setEmail("");
    setUserType(111);
  };
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSendInvite = () => {
    if (!email) {
      toast.error("Email field cannot be empty");
      return;
    }
    const { shareWorkspace } = props;

    const existingUser = users.find((user) => user.email === email);
    if (existingUser) {
      if (existingUser.id === userId) {
        toast.error("You cannot invite yourself");
      } else {
        toast.error("User already exists");
      }
      return;
    }
    shareWorkspace(email, userType, () => toast.success("Invitation sent successfully"));
    setEmail("");
    setUserType(111);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleSendInvite();
  };
  const handleUpdateUserType = (id, user_type) => {

    props.updateUserTypeInOrg(id, user_type);
  };
  const handleRemoveUser = (user) => {
    props.removeUserFromWorkspace(user, () => {
      toast.success("User removed successfully");
    });
  };
  const handleRemoveUserindb = (email, userId) => {
    props.removeUserFromWorkspace(email, userId);
    toast.success("User removed successfully");
  };

  const roleMap = {
    1: "Owner",
    11: "Admin",
    111: "User",
  };
  const getRole = (access) => roleMap[access] || "Unknown Role";

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper, // Apply custom styles to the dialog paper
      }}

      open={props.shareOrg} onClose={handleClose}>
      <ClickAwayListener
        onClickAway={handleClose}
      >
        <div
        >
          {" "}
          <div className="popupheader" style={{ marginBottom: '5%' }}>    <Typography sx={{ ml: 2 }} id="title" variant="h6" component="h2">
            {props?.title}
          </Typography><CloseIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleClose} /></div>
          <DialogContent>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <CustomTextField
                autoFocus
                margin="dense"
                placeholder="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                sx={{ flex: "3", marginRight: "1rem", marginLeft: -1 }}
              />
              <FormControl sx={{ flex: "1.2" }}>
                <InputLabel>User Type</InputLabel>
                <Select
                  value={userType}
                  MenuProps={{
                    disablePortal: true,
                    onClick: (e) => {
                      e.stopPropagation(); // Stop the event from propagating to the dialog
                    },
                  }}
                  label="selectusertype"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // Stop the event from propagating to the dialog
                  }}
                  onChange={handleUserTypeChange}
                >
                  <MenuItem value={111}>User</MenuItem>
                  <MenuItem value={11}>Admin</MenuItem>
                  {props?.useCase === "sharedb" && (
                    <MenuItem value={1}>Owner</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          {props?.useCase === "sharedb" && (
            <>
              <Box sx={{ m: 1, ml: 2 }}> Your role : {getRole(props.orgUsers?.[userId]?.access)} </Box>
              <Box sx={{ m: 1, ml: 2 }}> Created by: {props.dbCreatedBy || 'Not found'} </Box>
            </>
          )}
          <Box sx={{ display: "flex", m: 2, justifyContent: "space-between" }}>
            <Button
              variant="contained"
              className="mui-button"
              color="primary"
              onClick={handleSendInvite}
            >
              Send Invite
            </Button>
          </Box>
          {users?.length > 1 && (
            <Box sx={{ m: 1, ml: 2 }}>
              <Typography variant="h6">
                <strong>Shared with:</strong>
              </Typography>
            </Box>
          )}
          <Box
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{ ml: 2, width: '92%' }}
          >
            {users?.map((user, index) => {
              if (user?.id != userId) {
                return (
                  <Box
                    key={user?.email}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: index === props?.org?.users?.length - 1 ? 2 : undefined
                    }}

                  >
                    <Box sx={{ m: 1, ml: 0 }}>
                      <Typography>{user?.email}</Typography>
                    </Box>
                    { <Box sx={{ m: 1,ml:0 }}>
                        {props?.useCase === "sharedb" && (
                          editable === user ? (
                            <Select
                              sx={{ height: "30px" }}
                              value={user.access}
                              MenuProps={{
                                disablePortal: true,
                                onClick: (e) => {
                                  e.stopPropagation(); // Stop the event from propagating to the dialog
                                },
                              }}
                           
                              onChange={(e) => {
                                setEditable(null);
                                handleUpdateUserType(
                                  user?.id,
                                  e.target.value
                                );
                              }}
                            >
                              <MenuItem value={111}>User</MenuItem>
                              <MenuItem value={11}>Admin</MenuItem>
                              <MenuItem value={1}>Owner</MenuItem>
                            </Select>
                          ) : (
                            <Typography>
                              {user?.access === 111
                                ? "User"
                                : user?.access === 11
                                ? "Admin"
                                : "Owner"}
                            </Typography>
                          )
                        ) 
                        }
                      </Box>
                    }
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                   { props?.useCase === "sharedb" &&
                      <IconButton
                        sx={{p:0}}
                          aria-label="delete"
                          onClick={() => {
                            if (editable != user) {
                              setEditable(user);
                            } else setEditable(null);
                          }}
                        >
                        <ModeEditIcon
                         
                        />
                        </IconButton> 
              }
                      {userId == props.org.created_by &&  <IconButton
                        sx={{ p: 0 }}
                        aria-label="delete"
                        onClick={() => {
                          if (props?.useCase === "sharedb") {
                            handleRemoveUserindb(
                              user?.email,
                              user?.id
                            );
                          } else handleRemoveUser(user);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>}

                    </Box>
                  </Box>
                );
              } else {
                return null; // don't render the user if they are an admin or the current user
              }
            })}
          </Box>
        </div>
      </ClickAwayListener>
    </Dialog>
  );
}
ShareOrgModal.propTypes = {
  shareOrg: PropTypes.bool,
  setShareOrg: PropTypes.func,
  shareWorkspace: PropTypes.func,
  title: PropTypes.any,
  setOrg: PropTypes.any,
  org: PropTypes.object,
  useCase: PropTypes.any,
  removeUserFromWorkspace: PropTypes.func,
  updateUserTypeInOrg: PropTypes.func,
  orgUsers: PropTypes.object, 
  dbCreatedBy: PropTypes.any
};