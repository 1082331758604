import axiosInstance from '../interceptor/interceptor';
import proxyInstance from '../interceptor/proxyInterceptor';

const signUpUser = async (data) => {
    // return await axiosInstance.post("/users" ,data );
    return await proxyInstance.post("/clientUsers", data)
}

const loginUser = async (email) => {
    return await axiosInstance.post("/users/login", email);
}

const getCurrentUser = async () => {
    return await proxyInstance.get("/c/getDetails");
}

const signUpOnBE = async (data) => {
    return await axiosInstance.post("/users", data);
}

const getAllUsers = async () => {
    // return await axiosInstance.get("/users")
    return await proxyInstance.get("/c/getUsers")
}

const getUserById = async (userId) => {
    // return await axiosInstance.get(`/users/${id}`)
    return await proxyInstance.get(`/c/getUsers?ids=${userId}`)
}


const updateUser = async (id, data) => {
    return await axiosInstance.patch(`/users/${id}`, data);
}

const deleteUser = async (id) => {
    return await axiosInstance.delete(`/users/${id}`);
}

const logoutUser = async () => {
    return await proxyInstance.delete('/c/logout')
}

const findUserByEmail = async (email) => {
    return await axiosInstance.get(`/users/email/${email}`);
}





export {
    signUpUser,
    getAllUsers,
    getUserById,
    updateUser,
    deleteUser,
    findUserByEmail,
    loginUser,
    getCurrentUser, 
    signUpOnBE,
    logoutUser
}