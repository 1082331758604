// import { current } from '@reduxjs/toolkit';
import { removeUser, saveUser } from './userThunk';
import { UserType } from '../../types/userType'
import {  UserActionType } from '../../types/userType';
import { ActionReducerMapBuilder, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { NoInfer } from 'react-redux';

export const initialState: UserType = {
  status: 'idle',
  userLogIn: false,
  userLogOut: true,
  name : "",
  userEmail: "",
  userProfilePic: "",
  userId: ""
};

export const reducers: ValidateSliceCaseReducers<UserType, SliceCaseReducers<UserType>> = {
  add(state, action: UserActionType) {
    if (action.payload) {
      const { name, id, email } = action.payload[0];
      state.name = name
      state.userId = id;
      state.userEmail = email;
      state.userLogIn = true;
      state.userLogOut = false;
    }
  },
  remove(state: UserType) {
    state.name = '';
    // state.userLastName = '';
    state.userId = '';
    state.userEmail = '';
    state.userProfilePic = '';
    state.userLogIn = false;
    state.userLogOut = true;
  }
};
export function extraReducers(builder: ActionReducerMapBuilder<NoInfer<UserType>>): void {

  // export function extraReducers(builder:((builder: ActionReducerMapBuilder<NoInfer<UserType>>) => void)) {
  builder
    //   // save User
    .addCase(saveUser.pending, (state) => {

      state.status = "loading"

    })
    .addCase(saveUser.fulfilled, (state) => {

      state.status = "succeeded";

    })
    .addCase(saveUser.rejected, (state) => {


      state.status = "failed";

    })


    .addCase(removeUser.pending, (state: UserType) => {

      state.status = "loading"
    })
    .addCase(removeUser.fulfilled, (state: UserType) => {

      state.status = "succeeded";

    })
    .addCase(removeUser.rejected, (state: UserType) => {

      state.status = "failed";
    });
}


