import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDbInUser, getDbById, removeDbInUser, updateAccessOfUserInDb } from "../../api/dbApi"
import { restoreTable, updateTable } from "../../api/tableApi";
import { ShareDbPayloadThunkType, UpdateTableThunkPayloadType, TableDataType, AllTableDataType } from '../../types/alltablesDataType';
import { addUserAccessInOrgThunk } from "../database/databaseThunk";


export const createTable1 = createAsyncThunk (
    "tables/createTable1", async ({tables}:{tables:TableDataType}) =>{
        return tables;
    }
);

export const restoreTable1 = createAsyncThunk(
    'tables/restoreTable1', async (payload: any) => {
        const response = await restoreTable(payload.dbId, payload.tableName);
        return response?.data?.data;
    }
)

export const getTable1 = createAsyncThunk (
    "tables/getTable1",
     async (payload:any) =>{
        const data = await getDbById(payload?.dbId);
        return data.data.data
    }
);

export const removeTable1 = createAsyncThunk (
    "tables/removeTable1", async (payload:AllTableDataType) =>{
        return payload;
    }
);
export const updateTable1= createAsyncThunk (
    "tables/updateTable1", async (payload:UpdateTableThunkPayloadType) =>{

        const data = await updateTable(payload?.dbId,payload.tableName,payload.data1);
        return data.data.data.tables
    }
);
export const addDbInUserThunk=createAsyncThunk(
    "tables/addDbInUserThunk",async(payload:ShareDbPayloadThunkType, { getState, dispatch } : {getState : Function, dispatch : Function})=>{
        // const addedUser = await addUserInOrg({user:payload.data}).then(data => data.data.data);
        let addedUser:any;
        const state = await getState();
            for (const userId in state.dataBase.currentOrgUsers) {
                 const userDetails = state?.dataBase?.currentOrgUsers[userId];
                 if (userDetails?.email === payload?.data?.email.toLowerCase()) {
                   addedUser=userDetails;
                   break;
                 }
            }
            if(!addedUser){
                addedUser = await dispatch(addUserAccessInOrgThunk(payload)).then(data=>data.payload.addedUser)
            } 
       const response = await addDbInUser(payload.dbId,payload.adminId,{id:addedUser?.id,userAccess:payload.data?.userAccess});
       return response?.data?.data;
    }
)
export const removeDbInUserThunk=createAsyncThunk(
    "tables/removeDbInUserThunk",async(payload:any)=>{

       const response= await removeDbInUser(payload.dbId,payload.adminId,payload.data);
       const responsetosend={
           response:response,
           userId:payload?.userId
        }

       return responsetosend;
    }
)
export const updateAccessOfUserInDbThunk=createAsyncThunk(
    "tables/updateAccessOfUserInDbThunk",async(payload:ShareDbPayloadThunkType)=>{

       const response= await updateAccessOfUserInDb(payload.dbId,payload.adminId,payload.data);
       return response.data?.data;
    }
)

