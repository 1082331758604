import { useLocation } from 'react-router-dom'
// import config from '../config'
import { getCurrentEnvironment, setInCookies , getFromCookies } from '../utility/storageHelpers'

function WithAuth({ children }) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const domain = getCurrentEnvironment()
  const proxyKey = 'proxy_auth_token'
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    if (key === proxyKey || key === 'state') {
      if (key === proxyKey) {
        localStorage.setItem(key, value)
        key = domain
      }
      setInCookies(key, value)
    }
  })
  if(!localStorage.getItem(proxyKey)){
    localStorage.setItem(proxyKey, getFromCookies(domain));
  }
  return children
}
export default WithAuth
