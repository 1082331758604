import React from 'react';
import { useEffect } from 'react'
import { useLocation, useNavigate} from 'react-router-dom' //useNavigate
import { Box } from '@mui/system'
import LinearProgress from '@mui/material/LinearProgress'
import {getCurrentUser} from '../../api/userApi'
import { signUpOnBE } from '../../api/userApi';
import { useDispatch } from 'react-redux'
import { saveUser } from '../../store/user/userThunk';
import { getCurrentEnvironment, getFromCookies, removeCookie, setInCookies} from '../../utility/storageHelpers' //removeCookie, setInCookies
import "./authPage.scss"

function ProxyAuthPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const domain = getSubdomain()
  const environment = getCurrentEnvironment()
  const searchParams = new URLSearchParams(location.search)
  const queryParams = {}
  // Convert searchParams to an array and use array iteration
  Array.from(searchParams.entries()).forEach(([key, value]) => {
    queryParams[key] = value
  })

  async function runEffect() {
    if (!queryParams['proxy_auth_token'] && !getFromCookies(environment)) {
      // const previousUrlData = JSON.parse(getFromCookies(`${getCurrentEnvironment()}-previous_url`) || '{}')
      const configuration = {
        referenceId : process.env.REACT_APP_REFERENCE_ID,
        addInfo: {
          redirect_path : "/"
        },
        // state: process.env.REACT_APP_CURRENT_DOMAIN_URL,
        success: (data) => {
          // get verified token in response
          console.log('success response', data)
        },
        failure: (error) => {
          // handle error
          console.log('failure reason', error)
        }
      }

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.onload = () => {
        const checkInitVerification = setInterval(() => {
          if (typeof initVerification === 'function') {
            clearInterval(checkInitVerification)
            // eslint-disable-next-line no-undef
            initVerification(configuration)
          }
        }, 100)
      }
      script.src = 'https://proxy.msg91.com/assets/proxy-auth/proxy-auth.js'
      document.body.appendChild(script)
      redirectToProject()
    }
    redirectToProject()
  }

  useEffect(() => {
    runEffect()
  }, [location.search])


  const redirectToProject = async () => {
    const token = getFromCookies(getCurrentEnvironment())
    if (token) {
    const userInfo = await getCurrentUser();
      const userData = userInfo.data?.data[0]
      if (!userData) {
        removeCookie(getCurrentEnvironment())
        localStorage.clear()
        sessionStorage.clear()
      }
      localStorage.setItem("userid", userData.id);
      const response = await signUpOnBE({ ...userData, org_id: parseInt(queryParams['company_ref_id'], 10) })

      if (process.env.REACT_APP_API_ENVIRONMENT === 'local') {
        // localStorage.setItem('accessToken', response?.data?.data?.token)
        setInCookies(environment, response?.data?.data)
      }

      dispatch(saveUser());
      navigate("/org");
    }
  }


  return (
    <Box className='back-to-login flex-center-center' height="100vh">
      {!queryParams['proxy_auth_token'] ? (
        <div id ={process.env.REACT_APP_REFERENCE_ID}/>
      ) : (
        <Box className='w-25'>
          <LinearProgress />
        </Box>
      )}
    </Box>
  )
}

export default ProxyAuthPage
