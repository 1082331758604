import axios from "axios";
import { getCurrentEnvironment, getFromCookies, removeCookie } from "../utility/storageHelpers";
import { toast } from 'react-toastify'

const axiosInstance = axios.create({
    baseURL: "https://routes.msg91.com/api",
});

axiosInstance.interceptors.request.use(
    async (config) => {
        try {
            const env = getCurrentEnvironment();
            const token = (env === 'local') ? localStorage.getItem("proxy_auth_token") : getFromCookies(env);
            if (token) {
                config.headers["proxy_auth_token"] = token;
            }
            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    (error) => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        try {
            if (error?.response?.status === 401) {
                toast.error('Session Expired');
                removeCookie(getCurrentEnvironment());
                window.location.href = "/";
            }else{
                toast.error(error.response.data.message)
            }
        }
        catch (e) {
            toast.error(`Error occurred : ${e.message}`);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;