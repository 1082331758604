import React, { useState, useRef, useEffect, useCallback } from "react";
import { Autocomplete, Popover, TextField, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { getFilteredRows } from "../../api/rowApi";
import { useDispatch } from "react-redux";
import { updateCells } from "../../store/table/tableThunk";
import { debounce } from 'lodash';
import RowData from "../rowData/rowData";
import { toast } from "react-toastify";

function LinkAutoCompletePopup(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { handleClose } = props;
  const params = useParams();
  const [searchText, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const textFieldRef = useRef(null);
  const dropdownRef = useRef(null);
  const foreignKey = props?.linkAutoComplete?.referencedFieldId;

  const handleSearch = async (text, pageNumber = 1) => {
    setSearchText(text);
    setLoading(true);
    try {
      const response = await getFilteredRows(params?.dbId, props?.linkAutoComplete?.referencedTableName, props?.linkAutoComplete?.referencedFieldId, pageNumber, text);
      const newSuggestions = response.data.data.rows.map((obj) => JSON.stringify(obj));
      if (pageNumber === 1) {
        setSuggestions(newSuggestions);
      } else {
        setSuggestions((prev) => [...prev, ...newSuggestions]);
      }
      setHasMore(newSuggestions.length > 0);
    } catch (err) {
      toast.error("Failed to show suggestions.");
    } finally {
      setLoading(false);
    }
  };

  const debouncedHandleSearch = debounce((text) => handleSearch(text, 1), 500);

  const handleSelect = (value) => {
    setSelectedValue(value);
    const parsedValue = (() => {
      try {
        const data = JSON.parse(value);
        return typeof data === 'object' ? data[foreignKey] : value;
      } catch {
        return value;
      }
    })();

    dispatch(
      updateCells({
        columnId: props?.linkAutoComplete?.fieldId,
        rowIndex: props?.linkAutoComplete?.rowAutonumber,
        value: parsedValue,
        dataTypes: 'link',
        indexIdMapping: {
          [props.linkAutoComplete.rowAutonumber]: props?.linkAutoComplete?.cell[1],
        },
      })
    );
    handleClose();
  };

  const handleScroll = useCallback((event) => {
    const bottom = Math.floor((event.target.scrollHeight) / 10) === Math.floor((event.target.scrollTop + event.target.clientHeight) / 10)
    if (bottom && hasMore && !loading) {
      const nextPage = page + 1;
      setPage(nextPage);
      handleSearch(searchText, nextPage);
    }
  }, [hasMore, loading, page, searchText]);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      dropdown.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (dropdown) {
        dropdown.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, suggestions, hasMore, loading]);

  useEffect(() => {
    const fetchInitialSuggestions = async () => {
      setLoading(true);
      setPage(1);
      try {
        const response = await getFilteredRows(params?.dbId, props?.linkAutoComplete?.referencedTableName, props?.linkAutoComplete?.referencedFieldId, 1, '');
        let initialSuggestions = response.data.data.rows;
        const nullRow = {[foreignKey] : ''} //Object.keys(initialSuggestions[0]).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
        initialSuggestions.unshift(nullRow);
        initialSuggestions = initialSuggestions.map((obj) => JSON.stringify(obj));

        setSuggestions(initialSuggestions);
        setHasMore(initialSuggestions.length > 0);
      } catch (err) {
        toast.error("Failed to show suggestions");
      } finally {
        setLoading(false);
      }
    };

    fetchInitialSuggestions();
    setOpen(true);
  }, [params?.dbId, props?.linkAutoComplete?.referencedTableName]);

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus();
        }
      }, 100);
    }
  }, [props.open]);
  const { x, y , width } = props.linkAutoComplete.anchorPosition;
  const anchorPosition = {top : y-2, left : x-2};
  return (
    <Popover
      className="autocomplete-popper"
      anchorReference="anchorPosition"
      anchorPosition= {anchorPosition}
      id={props?.id}
      open={props?.open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        id="combo-box-demo"
        freeSolo
        options={suggestions}
        value={selectedValue}
        loading={loading}
        onChange={(event, value) => handleSelect(value)}
        sx={{ width : Math.max(300, width+4) }}
        ListboxProps={{
          ref: dropdownRef,
        }}
        renderOption={(props, option) => {
          option = JSON.parse(option);
          const heading = option?.[foreignKey]?.toString() || 'NULL';
          return (
            <li {...props}>
              <RowData  value={option} heading={heading} />
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={textFieldRef}
            onChange={(e) => debouncedHandleSearch(e.target.value)}
            value={searchText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Popover>
  );
}

LinkAutoCompletePopup.propTypes = {
  id: PropTypes.any,
  linkAutoComplete: PropTypes.any,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default LinkAutoCompletePopup;
