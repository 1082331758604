import { DbStateType,OrgObj,BulkAddData,CreateRestoreAndDeleteDBDetails,createAndUpdateOrgThunkData , ActionDataType, MovePayloadType, RenamePayloadType} from "../../types/databaseDataType";
import { ActionReducerMapBuilder, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { NoInfer } from 'react-redux';

import {
  renameDBThunk,createDbThunk,
  moveDbThunk,
  bulkAdd,
  renameOrgThunk,
  createOrgThunk,
  shareUserInOrgThunk,
  removeUserInOrgThunk,
  deleteDbThunk,
  restoreDbThunk,
  updateUserInOrgThunk,
  fetchAllOrgThunk,
  saveOrgs,
  getDbsOfOrg,
  switchOrgThunk,
  addUserAccessInOrgThunk
} from "./databaseThunk";
export const initialState: DbStateType = {
status: "idle",
  orgId: {},
  allOrg: [],
  currentOrgId : "",
  currentOrgUsers : {}
};

export const reducers: ValidateSliceCaseReducers<DbStateType, SliceCaseReducers<DbStateType>> = {
 
};

export function extraReducers(builder: ActionReducerMapBuilder<NoInfer<DbStateType>>): void {
  builder
    //    //   rename Db
    .addCase(moveDbThunk.fulfilled, (state, action:ActionDataType<MovePayloadType>) => {
      let currentDbs = state.orgId[action.payload.currentOrgId];
      state.orgId[action.payload.currentOrgId] = currentDbs.filter((db : any) => db._id !== action.payload.data1._id);
      // state.orgId = state.orgId[state.currentOrgId].filter(dbs)
    })
    .addCase(renameDBThunk.fulfilled, (state, action:ActionDataType<RenamePayloadType>) => {
      state.status = "succeeded";
      // let arr = state.orgId[action.payload.org_id] || [];
      // let object = arr.map((obj) => {
      //   if (obj._id == action.payload._id) {
      //     obj.name = action.payload.name;
      //     return obj;
      //   }
      //   return obj;
      // });
      // console.log(action.payload);
      // state.orgId = { ...state.orgId, [action.payload.org_id]: object };
      let newDbs = state.orgId[action.payload.org_id].map(db => db._id != action.payload._id ? db : {...db, name : action.payload.name});
      state.orgId[action.payload.org_id] = newDbs;
    })

    // bulkAdd
    .addCase(bulkAdd.pending, (state) => {
      state.status = "loading";
    })
    .addCase(bulkAdd.fulfilled, (state, action:ActionDataType<BulkAddData>) => {
      state.orgId = action.payload.result;
      state.allOrg = action.payload.allorgs;
      state.status = "succeeded";
    })
    .addCase(bulkAdd.rejected, (state) => {
      state.status = "failed";
    })
    .addCase(renameOrgThunk.fulfilled, (state, action:ActionDataType<OrgObj>) => {
      return {
        ...state, 
        allOrg : state.allOrg.map(org => org.id === action.payload.id ? action.payload : org)
      }
      // state.status = "succeeded";
      // const newState = {...state};
      // // let arr = state.orgId[action.payload.id] || [];
      // // state.allOrg = state.allOrg.map(org => {
      // //   if(org.id === action.payload.id) return action.payload;
      // //   return org
      // // })
      // for(let i in state.allOrg){
      //   if(state.allOrg[i].id === action.payload.id){
      //     state.allOrg[i] = action.payload;
      //   }
      // }
      // console.log(JSON.stringify(state));
      // // return {
      //   ...state, 
      //   allOrg : state.allOrg.map(org => {
      //     if(org.id != action.payload.id) return org;
      //     return action.payload
      //   })
      // }
      // arr.map((obj) => {
      //   obj.org_id.name = action.payload.name;
      // });
      // state.orgId = { ...state.orgId, [action.payload.id]: arr };
    })

    //   create Org

    .addCase(createOrgThunk.pending, (state) => {
      state.status = "loading";
    })
    .addCase(createOrgThunk.fulfilled, (state, action:ActionDataType<createAndUpdateOrgThunkData>) => {
      if(action.payload.allorgs){  
        state.status = "succeeded";
        let arr = state.orgId[action.payload.allorgs[0].id] || [];
        const newArr = [...arr, action.payload.data];
        state.orgId = { ...state.orgId, [action.payload.allorgs[0].id]: newArr };

        if (state.allOrg)
          state.allOrg = [...state.allOrg, action.payload.allorgs[0]];
        else state.allOrg = [action.payload.allorgs[0]];
      }
    })

    .addCase(createOrgThunk.rejected, (state) => {
      state.status = "failed";
      // MDBToast.error("Unable to fetch jamaats.");
    })

    // //   create Db

    .addCase(createDbThunk.pending, (state) => {
      state.status = "loading";
    })
    .addCase(createDbThunk.fulfilled, (state, action:any) => {//ActionDataType<CreateRestoreAndDeleteDBDetails>
      state.status = "succeeded";
      let arr = state.orgId[action?.payload?.org_id] || [];
      const newArr = [...arr, action.payload];
      state.orgId = { ...state.orgId, [action?.payload?.org_id]: newArr };
    })
    .addCase(createDbThunk.rejected, (state) => {
      state.status = "failed";
    })

    //   Delete Org

    // .addCase(deleteOrgThunk.pending, (state) => {

    //   state.status = "loading"
    // })
    // .addCase(deleteOrgThunk.fulfilled, (state, action) => {

    //   state.status = "succeeded";
    //   const deletedOrgId = action.payload;
    //   let orgIdArr = state.orgId;
    //   delete orgIdArr[deletedOrgId];
    //   state.orgId = { ...orgIdArr };
    //   state.allOrg = state.allOrg.filter(org => org._id !== deletedOrgId);

    // })
    // .addCase(deleteOrgThunk.rejected, (state) => {

    //   state.status = "failed";
    //   // MDBToast.error("Unable to fetch jamaats.");
    // })

    //   Delete Db

    
    //  add deleted db time for restoring again
    .addCase(deleteDbThunk.fulfilled, (state, actions:ActionDataType<CreateRestoreAndDeleteDBDetails>) => {
      const arr = state.orgId[actions.payload.org_id];
      let newArr = arr.filter((ele) => {
        return ele._id !== actions.payload._id;
      });
      newArr.push(actions?.payload);
      state.orgId[actions.payload.org_id] = newArr;
    })
    .addCase(restoreDbThunk.fulfilled, (state, actions:ActionDataType<CreateRestoreAndDeleteDBDetails>) => {      
      const arr = state.orgId[actions.payload.org_id];
      let newArr = arr.filter((ele) => {
        return ele._id !== actions.payload._id;
      });
      newArr.push(actions?.payload);
      state.orgId[actions.payload.org_id] = newArr;
    })
    .addCase(shareUserInOrgThunk.fulfilled, (state, action:ActionDataType<createAndUpdateOrgThunkData>) => {
        const { addedUser } = action.payload;
        state.currentOrgUsers[addedUser.id] = addedUser;
    })
    .addCase(addUserAccessInOrgThunk.fulfilled, (state, action:ActionDataType<createAndUpdateOrgThunkData>) => {
        const { addedUser } = action.payload;
        state.currentOrgUsers[addedUser.id] = addedUser;
    })
    

    //   Remove user from Org
    .addCase(updateUserInOrgThunk.pending, (state) => {
      state.status = "loading";
    })
    .addCase(updateUserInOrgThunk.fulfilled, (state, action:any) => {
      var arr = state.allOrg;
      arr.find((temp, index) => {
        if (temp.id == action.payload.allorgs[0].id) {
          arr[index] = action.payload.allorgs[0];
        }
      });
      state.allOrg = arr;
      state.status = "succeeded";
    })
    .addCase(removeUserInOrgThunk.fulfilled, (state, action:ActionDataType<createAndUpdateOrgThunkData>) => {
      // var arr = state.allOrg;
      // arr.find((temp, index) => {
      //   if (temp.id == action.payload?.allorgs?.[0]?.id) {
      //     arr[index] = action.payload.allorgs[0];
      //   }
      // });
      // state.allOrg = arr;
      if(action.payload.userId){
        delete state.currentOrgUsers[action.payload.userId];
      }
      state.status = "succeeded";
    })
    .addCase(fetchAllOrgThunk.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(fetchAllOrgThunk.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      // state.fetchError = ''
      const newEntities = payload.payload;
      // if (payload?.payload?.length) {
      //   payload.payload?.forEach((org) => {
      //     const { id } = org
      //     newEntities[id] = org
      //   })
      // }
      state.allOrg = newEntities
      if (state.currentOrgId?.length === 0) {
        state.currentOrgId = payload?.payload[0]?.id
      }
      state.status = "succeeded"
    })
    .addCase(fetchAllOrgThunk.rejected, (state) => {
      state.status = 'failed'
    })
    .addCase(saveOrgs.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(saveOrgs.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        state.allOrg = [ ...state.allOrg, payload.payload ].sort((a, b) => a.name.localeCompare(b.name));
        // successToast('Organization Created Successfully')
      }
    })
    .addCase(saveOrgs.rejected, (state) => {
      state.status = 'failed'
    })
    .addCase(getDbsOfOrg.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(getDbsOfOrg.fulfilled, (state, action : any) => {
      state.status = 'succeeded';
      state.orgId = {
        [action.payload.orgId] : action.payload.dbs || []
      }
      let users = {};
      for(let user of action.payload.users){
        users[user.id] = user;
      }
      state.currentOrgUsers = users;
    })
    .addCase(getDbsOfOrg.rejected, (state) => {
      state.status = 'failed';
    })
    .addCase(switchOrgThunk.fulfilled, (state, action)=> {
      state.status = 'succeeded';
      state.currentOrgId = action.payload.orgId;
    })
}